import React, { useState, useEffect, useCallback, useRef } from 'react'
import SVG from 'react-inlinesvg'
import moment from 'moment'
import { useAutoMaxHeight } from '../signup/shared'
import { Terms } from '../signup/terms'
import { useConnectGet, useSnackbar, varClass, parseQuery } from '../shared'
import { postConnect } from '../../services'

const getPublisherLogoVariant = (
  publisherName: string
): 'berlingske' | 'minetilbud' | 'rewardsweb' | 'heylink' => {
  switch (publisherName) {
    case 'Berlingske Media':
      return 'berlingske'
    case 'Minetilbud':
      return 'minetilbud'
    case 'Rewardsweb':
      return 'rewardsweb'
    default:
      return 'heylink'
  }
}

const getAttributionModelLabel = (attributionModel: string) => {
  switch (attributionModel) {
    case 'default':
      return 'Attribute all in cookie period'
    case 'lastKnown':
      return 'Last click from known sources'
    case 'session':
      return 'Last click from all sources'
    default:
      return ''
  }
}

const formatCommissionValue = (str: string): string => {
  if (str.endsWith('%')) {
    const value = parseFloat(str.replace('%', ''))
    return value.toFixed(2).replace('.', ',').replace(/\,0+$/, '') + '%'
  } else {
    const value = parseFloat(str)
    if (Number.isInteger(value)) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    } else {
      return value
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  }
}

export const ChangesPage = (props) => {
  const contractUuid = props.params['*']
  const data = useConnectGet(
    `/connect/v1/inbound/changes/${contractUuid}`,
    {},
    [contractUuid]
  )

  const [step, setStep] = useState(() => {
    const params = parseQuery(window.location.search)
    const initPage = parseInt(params.page || '')
    if (initPage && initPage >= 1 && initPage <= 6) {
      return initPage
    } else {
      return 1
    }
  })

  const [termsShown, setTermsShown] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)

  const stepProps = {
    contractUuid,
    data,
    termsShown,
    setTermsShown,
    termsAccepted,
    setTermsAccepted,
  }

  const [override, setOverride] = useState('')
  useEffect(() => {
    if (data?.error) {
      window.location = '/404/'
    }

    if (data?.publisher?.name === 'Rewardsweb') {
      setOverride('rewardsweb')
    }
  }, [data])

  const loaded = data && data.publisher && data.advertiser
  if (!loaded) {
    return (
      <div className="loader-container">
        <div className="loader-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }

  const logoVariant = getPublisherLogoVariant(data.publisher.name)
  data.publisher.names = `${data.publisher.name}’${
    data.publisher.name.toLowerCase().endsWith('s') ? '' : 's'
  }`

  return (
    <div
      className={varClass({
        [`override-${override}`]: !!override,
      })}
    >
      <div className="main-header">
        {logoVariant === 'rewardsweb' ? (
          <img
            src={data.publisher.logoUrl || `/images/logo-${logoVariant}.png`}
            className="header-logo"
            width={187}
            height={22}
          />
        ) : (
          <SVG
            src={data.publisher.logoUrl || `/images/logo-${logoVariant}.svg`}
            className="header-logo"
          />
        )}
        {step === 1 && (
          <div className="header-code">
            <b>{data.publisher.name}</b> - {data.contract.code}
          </div>
        )}
      </div>

      {step === 1 && <Signup1 goNext={() => setStep(2)} {...stepProps} />}
      {step === 2 && <Signup2 />}
    </div>
  )
}

const Signup1 = (props) => {
  const {
    contractUuid,
    data,
    goNext,
    termsShown,
    setTermsShown,
    termsAccepted,
    setTermsAccepted,
  } = props

  const [submitting, setSubmitting] = useState(false)

  const contentRef = useRef(null)
  const [error, setError] = useState(false)

  const acceptRef = useRef(null)
  const [acceptBlinked, setAcceptBlinked] = useState(false)

  const showTerms = useCallback(() => {
    if (!termsShown) {
      setTermsShown(true)
      const interval = setInterval(() => {
        if (contentRef.current) {
          contentRef.current.scrollTop = contentRef.current.scrollHeight
        }
      }, 30)
      setTimeout(() => {
        clearInterval(interval)
      }, 500)
    }
  }, [])

  useAutoMaxHeight(contentRef)

  const { showSnackbar } = useSnackbar()

  const signContract = useCallback(async () => {
    setSubmitting(true)
    const response = await postConnect(
      `/connect/v1/inbound/changes/${contractUuid}/sign`
    )
    if (response.data?.error) {
      showSnackbar(response.data.error)
    }
    setSubmitting(false)
    goNext()
  }, [contractUuid])

  return (
    <div className="signup-step changes-step-1">
      <div className="main-content">
        <div className="text-center">
          <h1>Campaign terms</h1>
          <h2 className="m-b-6">
            Review campaign terms changes suggested by your partner! For any
            questions or assistance, feel free to contact them.
          </h2>
        </div>

        <div className="terms-container" ref={contentRef}>
          <div className="campaign-terms">
            <div className="row">
              <div className="flex-1-2">
                <div className="field">
                  <div className="label">Start date</div>
                  <div className="value">
                    <SVG src="/images/icon-calendar.svg" />
                    {data.contract.startsAt || moment().format('YYYY-MM-DD')}
                  </div>
                </div>
              </div>
              <div className="flex-1-2">
                <div className="field">
                  <div className="label">End date</div>
                  <div className="value">
                    <SVG src="/images/icon-calendar.svg" />
                    {data.contract.endsAt === 'Invalid date'
                      ? '-'
                      : data.contract.endsAt}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="flex-1-2">
                <div className="field">
                  <div className="label">Campaign name</div>
                  <div className="value">{data.contract.code}</div>
                </div>
              </div>
              <div className="flex-1-2">
                <div className="field">
                  <div className="label">URL</div>
                  <div className="value">{data.contract.url}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="flex-1-3">
                <div className="field">
                  <div className="label">Commission model</div>
                  <div className="value">{data.contract.commissionModel}</div>
                </div>
              </div>
              <div className="flex-1-3">
                <div className="field">
                  <div className="label">Commission</div>
                  <div className="value">
                    {formatCommissionValue(data.contract.commissionValue)}
                  </div>
                </div>
              </div>
              <div className="flex-1-3">
                <div className="field">
                  <div className="label">Currency</div>
                  <div className="value">{data.contract.currency}</div>
                </div>
              </div>
            </div>

            <div className="row">
              {data.contract.couponCode && (
                <div className="flex-1">
                  <div className="field">
                    <div className="label">
                      Coupon{' '}
                      {!data.contract.requireCouponCode ? (
                        <span className="text-light text-small">
                          (required)
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="value">{data.contract.couponCode}</div>
                  </div>
                </div>
              )}
              <div className="flex-1">
                <div className="field">
                  <div className="label">Tracking period</div>
                  <div className="value">
                    {data.contract.trackingPeriod}{' '}
                    {data.contract.trackingPeriodType}
                  </div>
                </div>
              </div>
              {data.contract.disableDeeplinks && (
                <div className="flex-1">
                  <div className="field">
                    <div className="label">Deeplinks</div>
                    <div className="value">Disabled</div>
                  </div>
                </div>
              )}
            </div>

            {getAttributionModelLabel(data.contract.customAttributionModel) && (
              <div className="row">
                <div className="flex-1">
                  <div className="field">
                    <div className="label">Attribution model</div>
                    <div className="value">
                      {getAttributionModelLabel(
                        data.contract.customAttributionModel
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={varClass({
              'terms-label': true,
              'shown': !termsShown,
            })}
          >
            Please read to continue
          </div>
          <button className="link row row-center row-fill" onClick={showTerms}>
            <SVG src="/images/icon-book.svg" className="m-r-2" />
            {termsShown ? (
              <span className="text-boldest">Reading Terms of service</span>
            ) : (
              <span>Read Terms of service</span>
            )}
          </button>

          <div
            className={varClass({
              'heylink-terms': true,
              'shown': termsShown,
            })}
          >
            <div
              className="terms m-y-8"
              onScroll={(event) => {
                if (
                  event.target.scrollTop >=
                  event.target.scrollHeight - event.target.offsetHeight
                ) {
                  if (!acceptBlinked) {
                    if (acceptRef.current) {
                      acceptRef.current.classList.add('blinking')
                      setTimeout(() => {
                        if (acceptRef.current) {
                          acceptRef.current.classList.remove('blinking')
                        }
                      }, 3000)
                      setAcceptBlinked(true)
                    }
                  }
                }
              }}
            >
              <Terms publisher={data.publisher.name} />
            </div>

            <div className="text-left">
              <div className="control control-checkbox m-b-1" ref={acceptRef}>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={(event) => {
                    setError(false)
                    setTermsAccepted(event.target.checked)
                  }}
                />
                <label
                  className={varClass({
                    'p-l-8': true,
                    'text-red': error && !termsAccepted,
                  })}
                >
                  By signing up you are creating an account and you agree Terms
                  of service and Privacy Policy.
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="main-action text-right">
          <button
            className={varClass({
              'btn btn-primary vertical-middle': true,
              'disabled2': !termsAccepted || submitting,
            })}
            onClick={() => {
              if (submitting) return
              if (!termsShown) {
                showTerms()
                return
              }
              if (termsAccepted) {
                signContract()
              } else {
                setError(true)
              }
            }}
          >
            <div
              className={varClass({
                'spinner-container inline-block': true,
                'shown': submitting,
              })}
            >
              <div className="spinner" />
            </div>
            <span className="inline-block m-t--1">Accept</span>
          </button>
        </div>
      </div>
      <div className="main-footer text-right">
        Powered by
        <SVG src="/images/heylink-logo.svg" />
      </div>
    </div>
  )
}

const Signup2 = (props) => {
  const done = () => {
    window.close()
  }

  return (
    <div className="signup-step changes-step-2">
      <div className="main-content">
        <div className="text-dark text-largest text-boldest m-t--60 m-b-30">
          New campaign terms applied
        </div>
        {/*
        <div className="m-b-6">
          We've sent an email your way and it should arrive within just 15
          minutes.
        </div>
        */}
        <button className="link text-small text-bold p-x-0" onClick={done}>
          You may close this window
        </button>
      </div>
      {/*
      <div className="conversion-roadmap">
        <ResponsiveImg
          src="/images/conversion-test.jpg"
          className="conversion-background"
        />
        <div className="roadmap">
          <div className="roadmap-entry green">
            <div className="roadmap-entry-content">
              <CheckStatus status="Tracking Tag added" green />
              <div className="text-bigger text-bolder m-t-2 m-b-05">
                The Heylink Tracking Tag
              </div>
              <div className="text-big">
                Well done! You've accomplished the task flawlessly.
              </div>
            </div>
          </div>
          <div className="roadmap-entry green">
            <div className="roadmap-entry-content">
              <CheckStatus status="Conversion Tag added" green />
              <div className="text-bigger text-bolder m-t-2 m-b-05">
                The Heylink Tracking Tag
              </div>
              <div className="text-big">
                Great! You've successfully added the tag to your website. You
                now have access to valuable insights!
              </div>
            </div>
          </div>
          <div className="roadmap-entry">
            <div className="roadmap-entry-content">
              <CheckStatus status="Email sent" />
              <div className="text-bigger text-bolder m-t-2 m-b-05">
                We've just sent an email your way
              </div>
              <div className="text-big">
                Check your inbox to find all the important information you need.
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
      <div className="main-footer">
        Powered by
        <SVG src="/images/heylink-logo.svg" />
      </div>
    </div>
  )
}
