import React from 'react'
import { Layout } from '../components/layouts/signup'
import { ChangesPage } from '../components/changes'

export default function Page(props) {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      <ChangesPage {...props} />
    </Layout>
  )
}
